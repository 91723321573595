import type { ActivityType } from '@mntn-dev/domain-types'
import { useLanguage, useTranslation } from '@mntn-dev/i18n'
import { SystemUserId } from '@mntn-dev/session-manager'
import { Avatar, Feed } from '@mntn-dev/ui-components'
import { type ComponentType, type PropsWithChildren, useMemo } from 'react'
import {
  AgreementTermsAccepted,
  CommentCreated,
  PostProductionReviewBrandFeedbackSubmitted,
  PostProductionReviewMakerProposalSubmitted,
  PreProductionBrandFeedbackSubmitted,
  PreProductionMakerProposalSubmitted,
  PreProductionReviewBrandFeedbackSubmitted,
  PreProductionReviewMakerProposalSubmitted,
  ProjectBiddingOpened,
  ProjectCreated,
  ProjectFileAdded,
  ProjectNotMatched,
  ProjectOfferExpired,
  ProjectOfferPending,
  ProjectOffersSent,
  ProjectServiceAdded,
  ProjectStatusChanged,
  ServiceNoteAdded,
} from './components/activity-details/index.ts'
import type { ActivityDetailsProps } from './components/activity-details/types.ts'
import type { ActivityProps } from './types.ts'

const ActivityContainer = ({
  activity: { activityId, timestamp, person, actorId },
  indicator,
  children,
  scroll,
}: PropsWithChildren<
  Pick<ActivityProps, 'activity' | 'indicator' | 'scroll'>
>) => {
  const languageId = useLanguage()

  const isSystemActivity = actorId === SystemUserId

  return (
    <Feed.Item
      avatar={
        person && !isSystemActivity ? (
          <Avatar.User person={person} />
        ) : (
          <Avatar.Icon name="ExclamationIcon" />
        )
      }
      key={activityId}
      timestamp={timestamp}
      languageId={languageId}
      indicator={indicator}
      scroll={scroll}
      dataTestId={`activity-feed-item-${activityId}`}
      dataTrackingId={`activity-feed-item-${activityId}`}
    >
      {children}
    </Feed.Item>
  )
}

type ActivityComponentMap = {
  [K in ActivityType]: ComponentType<ActivityDetailsProps<K>>
}

const activityComponentMap: ActivityComponentMap = {
  agreement_terms_accepted: AgreementTermsAccepted,
  comment_created: CommentCreated,
  post_production_review_brand_feedback_submitted:
    PostProductionReviewBrandFeedbackSubmitted,
  post_production_review_maker_proposal_submitted:
    PostProductionReviewMakerProposalSubmitted,
  pre_production_brand_feedback_submitted: PreProductionBrandFeedbackSubmitted,
  pre_production_maker_proposal_submitted: PreProductionMakerProposalSubmitted,
  pre_production_review_brand_feedback_submitted:
    PreProductionReviewBrandFeedbackSubmitted,
  pre_production_review_maker_proposal_submitted:
    PreProductionReviewMakerProposalSubmitted,
  project_bidding_opened: ProjectBiddingOpened,
  project_created: ProjectCreated,
  project_file_added: ProjectFileAdded,
  project_offer_expired: ProjectOfferExpired,
  project_offer_pending: ProjectOfferPending,
  project_offers_sent: ProjectOffersSent,
  project_not_matched: ProjectNotMatched,
  project_service_added: ProjectServiceAdded,
  project_status_changed: ProjectStatusChanged,
  service_note_added: ServiceNoteAdded,
}

const getActivityComponent = <K extends ActivityType>(
  type: K,
  map: ActivityComponentMap
): ComponentType<ActivityDetailsProps<K>> => map[type]

export const Activity = (props: ActivityProps) => {
  const {
    activity: { details },
  } = props
  const { t } = useTranslation('activity')

  const ActivityContent = useMemo(
    () => getActivityComponent(details.activityType, activityComponentMap),
    [details.activityType]
  )

  return (
    <ActivityContainer {...props}>
      <ActivityContent {...{ ...props, t }} />
    </ActivityContainer>
  )
}
